<template>
    <div class="token-editor-wrap">
        <div
            v-outside-click="closeMenu"
            class="token-add"
            :class="{
                'token-add-menu-open': menuOpen
            }">
            <div
                class="token-menu-wrap">
                <button @click="menuOpen = !menuOpen">
                    <icon
                        name="indicator-add"
                        size="20"
                        :color="colors.gray" />
                </button>
                <div
                    v-if="menuOpen"
                    class="token-menu">
                    <div class="token-menu-inner">
                        <a
                            v-for="token in tokens"
                            :key="token.field"
                            href="#"
                            @click.prevent="insertToken(token)">
                            {{ token.label }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div
            ref="editable"
            class="token-editor"
            contenteditable
            @click="handleClick"
            @keydown="handleKeyDown"
            @input="onInput" />
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import colors from '@/helpers/colors';

export default {
    components: {
        Icon
    },
    props: {
        value: {
            type: String,
            required: true,
        },
        tokens: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            colors,
            menuOpen: false,
            selectedToken: null
        };
    },
    mounted() {
        this.$refs.editable.innerHTML = this.stringToEditor(this.value);
    },
    methods: {
        onInput() {
            this.emitInput();
        },
        insertToken(token) {
            this.menuOpen = false;
            this.placeToken(token);
            this.emitInput();
        },
        emitInput() {
            const output = this.editorToString();
            this.$emit('input', output);
        },
        closeMenu() {
            this.menuOpen = false;
        },
        handleKeyDown(event) {
            const keycode = event.keyCode;

            // Direction
            if ([37, 38, 39, 40].includes(keycode)) {
                if (this.selectedToken) {
                    this.selectedToken.classList.remove('highlight');
                }
            }

            // Delete keys
            if (keycode === 8 || keycode === 46) {
                if (this.selectedToken) {
                    this.selectedToken.remove();
                }
            }

            // Handle returns
            if (keycode === 13) {

                event.preventDefault();
                const selection = window.getSelection();
                const range = selection.getRangeAt(0);
                const br = document.createElement('br');
                range.deleteContents();
                range.insertNode(br);
                range.collapse(false);
                selection.removeAllRanges();
                selection.addRange(range);

                // Since we kill the default we need to emit the update
                this.emitInput();

                return false;
            }
        },
        handleClick(event) {
            if (event.target.classList.contains('token-editor-token')) {
                event.target.classList.add('highlight');
                this.selectedToken = event.target;
            } else if (this.selectedToken) {
                this.selectedToken.classList.remove('highlight');
            }
        },
        placeToken(token) {
            const tokenElem = this.createTokenElem(token);
            const selection = window.getSelection();

            let range;

            // If the cursor is in the area place the token at it
            if (selection.focusNode.parentElement === this.$refs.editable) {

                range = selection.getRangeAt(0);
                range.deleteContents();

                const spaceNode = document.createTextNode(' ');

                range.insertNode(spaceNode);

                range.insertNode(tokenElem);

            } else {

                range = document.createRange();
                range.selectNodeContents(this.$refs.editable);
                range.collapse(false);

                selection.removeAllRanges();
                selection.addRange(range);

                range.insertNode(tokenElem);
            }
        },
        createTokenElem(token) {

            const tokenElem = document.createElement('span');
            tokenElem.className = 'token-editor-token';
            tokenElem.dataset.field = token.field;
            tokenElem.textContent = token.label;
            tokenElem.setAttribute('contenteditable', false);

            return tokenElem;
        },
        editorToString() {
            let string = '';
            this.$refs.editable.childNodes.forEach(node => {
                if (
                    node instanceof HTMLElement &&
                    node.classList.contains('token-editor-token')
                ) {

                    string += `{${node.dataset.field}}`;

                } else if (
                    node instanceof HTMLElement &&
                    node.nodeName === 'BR'
                ) {

                    string += '\n';

                } else {
                    string += node.textContent;
                }
            });
            return string;
        },
        stringToEditor(string) {

            const tokenString = this.tokens.map(token => {
                return token.field.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
            }).join('|');

            // Create regex using the keys of the replacement object.
            const regex = new RegExp(`{(${tokenString})}`, 'g');

            return string
                .replace(regex, (original, field) => {

                    const token = this.tokens.find(token => token.field == field);

                    if (!token) {
                        return original;
                    }

                    return `<span class="token-editor-token" data-field="${field}" contenteditable="false">${token.label}</span>`;
                })
                .replace(/\n/g, '<br />');
        }
    },
};
</script>

<style lang="scss">
.token-editor-token {
    background-color: $gray-dark;
    color: $white;
    border-radius: 2px;
    padding: 2px 5px;
    text-transform: uppercase;
    font-size: 0.8rem;
    line-height: 1.2rem;
    display: inline-block;
    transition: background-color 0.05s ease-in-out;
    &::selection {
        background: transparent;
        color: $white;
    }
    &.highlight {
        background-color: $black;
    }
}
</style>

<style lang="scss" scoped>
.token-editor-wrap {
    position: relative;
    padding: 10px;
    &:hover {
        .token-add {
            opacity: 1;
        }
    }
}

.token-editor {
    white-space: pre-wrap;
    display: inline-block;
    &:focus {
        outline: none;
    }
}

.token-add {
    position: absolute;
    top: -3px;
    right: -3px;
    z-index: 100;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    &.token-add-menu-open {
        button {
            svg {
                transform: rotate(135deg);
            }
        }
    }
}

.token-menu-wrap {
    position: relative;
    top: 0;
    right: 0;
    button {
        svg {
            transition: transform 0.15s ease-in-out;
        }
    }
    .token-menu {
        position: absolute;
        right: 0;
        padding-top: 8px;
        &::after {
            content: '';
            position: absolute;
            bottom: calc(100% - 8px);
            z-index: 100;
            right: 5px;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent $gray-darker transparent;
        }
        .token-menu-inner {
            max-height: 210px;
            overflow-y: scroll;
        }
        a {
            display: block;
            color: $white;
            border-bottom: 1px solid $gray-light;
            text-decoration: none;
            white-space: nowrap;
            font-size: 0.85rem;
            padding: 5px 10px;
            transition: background-color 0.2s ease-in-out;
            background-color: $gray-darker;
            &:hover {
                background-color: $gray;
            }
            &:last-child {
                border-bottom: none;
            }
        }
    }
}
</style>